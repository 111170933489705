@import "./base/base";
@import "./abstracts/base";
@import "./components/base";

body {
    font-weight: 400;
}

a {
    text-decoration: underline;
}

.gm-style .gm-style-iw-c {
    background: #fff;
    border: 1px solid #5bb091;
    border-radius: 6px;
    width: 200px;
    box-shadow: 0 3px 6px 0 #00000029;
    padding: 0px;
}
.gm-style .gm-style-iw {
    color: #000;
}
.gm-style .gm-style-iw-t::after {
    transform: translate(-50%, -55%) rotate(-45deg);
    border-bottom: 1px solid #5bb091;
    border-left: 1px solid #5bb091;
}

.gm-style-iw-d {
    overflow: auto !important;
    + {
        button.my-close {
            > img {
                height: 1px !important;
            }
            right: 0 !important;
        }
    }
}
.spl-pop {
    --width: 80vw;
    ion-backdrop {
        --backdrop-opacity: 0.33;
        background: rgb(0, 0, 0, 0.7);
    }
}
.gm-style-cc {
    display: none;
}
.searchbar-input {
    //  background: var(--ion-color-tertiary-contrast) !important;
    border-radius: 5px;
    background: var(--ion-background-color, #fff) !important;
}
