@import "./../abstracts/variables";

ion-checkbox {
    --border-color: var(--ion-color-primary);
    --border-color-checked: var(--ion-color-primary);
    --background-checked: var(--ion-color-primary);
    --checkmark-color: var(--ion-color-white);
    --checkmark-width: 2px;
    --border-width: 1px;
    --border-radius: 3px;
    --size: 24px;
    --color: var(--ion-color-dark);

    margin: 11px 13px auto 0;
}

.checkbox-item {
    --ripple-color: transparent;
    --background-hover: transparent;

    font-size: 14px;
    line-height: 24px;

    // ion-label {
    //     color: $light-grey !important;
    // }
    a {
        line-height: 24px;
    }
}
