@import "./../abstracts/variables";

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-color-invalid: var(--highlight-color-focused);

    border-color: $light-grey1;

    &.ion-touched {
        --highlight-color-invalid: var(--ion-color-danger);
    }

    ion-label {
        font-size: 16px;
        line-height: 18px;

        &.label-floating.sc-ion-label-md-h {
            color: var(--ion-color-medium);
            transform: translateY(170%);
        }
    }

    ion-input {
        --font-size: 16px;
        --padding-top: 0;
        --padding-bottom: 0;
        --color: var(--ion-color-medium);
        --background-color: transparent;
        // --placeholder-color: var(--ion-color-medium);
    }
}

ion-text {
    &.error {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
        opacity: 1;

        &:not(.upload) {
            position: absolute;
            z-index: 2;
            // bottom: -10px;
        }
    }
}

ion-radio-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    & ion-item {
        margin-right: 23px;
    }

    & ion-radio {
        --border-width: 1px;

        width: 27px;
        height: 27px;
        margin-inline: 0 12px;

        & .radio-inner {
            width: 13px;
            height: 13px;
        }
    }
}

.native-input[disabled] {
    opacity: 1 !important;
}

.item-has-focus {
    & .label-floating {
        &.sc-ion-label-md-h {
            &:not(.ion-color) {
                color: var(--ion-color-medium);
            }
        }
    }
}
