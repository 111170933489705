/**
 * Basic tpography style for copy text
 */

h1 {
    font-size: 20px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 12px;
}

h5 {
    font-size: 11px;
}

h6 {
    font-size: 10px;
}

p {
    font-size: 14px;
    line-height: 24px;
}
