@import './../abstracts/variables';

ion-button {
    --border-radius: 6px;

    font-size: 12px;
    height: 44px;
    color: #fff;
    position: relative;
    margin: 4px 0;
    text-transform: uppercase;
    letter-spacing: 0.6pt;

    &.btn-big {
        height: 44px !important;
    }

    &.fb-btn ion-icon,
    &.google-btn ion-icon {
        position: absolute;
        left: calc(7% - 20px);
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &.fb-btn {
        --background: linear-gradient(to right, #486fac, #486fac 14%, #507cc0 14%, #507cc0 100%);

        margin-bottom: 12px;
        text-transform: none;
    }
&.apple-login{
    text-transform: none;
}
    &.google-btn {
        --background: linear-gradient(to right, #c8422b, #c8422b 14%, #df4930 14%, #df4930 100%);

        text-transform: none;
        margin-bottom: 12px;
        ion-icon {
            // font-size: 28px;
        }
    }

    &.submit-btn {
        margin-top: 29px;
    }

    &.file-upload-btn {
        --background: #5bb0914c;
        --box-shadow: none;

        color: #5bb091;
        height: 32px;
        margin-top: 10px;

        & ion-icon {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    &.add-button,
    &.remove-button {
        --border-radius: 50%;
        --background: transparent;

        background-image: url(../../icons/w08.svg);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-color: #5bb091;
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.remove-button {
        background-image: url(../../icons/w09.svg);
    }

    &.add-button {
        margin: auto auto 11px auto;
    }

    &.map-infobox-btn {
        height: 23px;
        font-size: 12px;
        text-transform: none;
        font-weight: 400;
    }
}

.img-upload-box {
    & .remove-button {
        margin: 0;
        position: absolute;
        top: 0;
        right: 2px;
        width: 18px;
        height: 18px;
        background-size: 8px 8px;

        --padding-start: 0;
        --padding-end: 0;
    }
}

ion-router-link {
    cursor: pointer;
    font-size: 12px;
    display: block;
    // text-align: center;
    line-height: 14px;

    &.btn-default {
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
    }

    &.btn-link {
        text-decoration: underline;
    }
}

.form-tab {
    background: #cee7de;
    border-radius: 18px;
    margin: 0;
    // height: 36px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    width: calc(100% - 40px);
    left: 20px;
    z-index: 999;

    & .step-tab {
        height: 36px;
        font-size: 12px;
        background: #cee7de;
        margin: 0;
        padding: 4px;
        flex-basis: 33.3%;
        border-radius: 18px;
        color: #010100;

        &.active {
            background: #5bb091;
        }

        & .step-index {
            width: 28px;
            height: 28px;
            background-color: #fff;
            border-radius: 18px;
            margin: 0 6px 0 0;
            text-align: center;
            line-height: 28px;
        }
    }
}
