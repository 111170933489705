@media (prefers-color-scheme: dark) {
    .profile-list-icon {
        filter: invert(100%);
    }
    .in-toolbar {
        ion-button {
            img {
                filter: invert(100%);
            }
        }
    }
}

ion-content {
    --padding-start: 15px;
    --padding-end: 15px;
    --color: var(--ion-color-dark);
}

.content-wrap {
    max-width: 480px;
    margin: auto;
}

.legend-text {
    position: relative;
    text-align: center;
    font-size: 12px;
    margin: 20px 0;
    text-transform: uppercase;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 49%;
        height: 1px;
        width: calc(50% - 16px);
        background: $light-grey2;
    }
    &::before {
        left: 0;
    }
    &::after {
        right: 0;
    }
}

.no-border {
    --border-color: transparent;
    border: none;
}

.round-box {
    border-radius: 50%;
}

ion-chip.selected-option {
    margin: 6px 15px 0 0;
    padding: 6px 46px 6px 12px;
    background: #0101000d;
    & ion-icon {
        position: absolute;
        right: 0;
        top: 0;
        height: 32px;
        width: 32px;
        margin: 0;
        color: var(--ion-color-primary);
    }
}

hr {
    background: #01010019;
    width: 100%;
    margin: 0;
}

.col-2-wrap {
    margin: 0 -5px;
}

.info-text {
    opacity: 0.7;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    font-weight: 500;
    justify-content: center;
    margin-top: 11px;
}

.desc-text {
    opacity: 0.7;
    font-size: 10px;
    line-height: 16px;
}

.profile {
    &-pic-wrap {
        position: relative;
        // width: 120px;
        margin: auto;
        margin-top: 35px;
        &.uploaded-image {
            margin: 0;
            flex-basis: 72px;
            & img {
                height: 60px;
                width: 60px;
            }
        }
    }
    &-pic {
        // height: 120px;
        // width: 120px;
        border: 2px solid;
        border-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 4px #5bb091;
    }
    &-blue-button {
        position: relative;
        top: 3px;
    }
    &-name {
        font-weight: 500;
        margin-top: 4px;
        margin-bottom: -5px;
    }
    &-subtitle {
        font-size: 13px;
    }
    &-detail-list {
        margin-top: -3px;
        & ion-router-link {
            text-decoration: none;
        }
        & ion-item {
            position: relative;
            margin-bottom: 10px;
            --padding-start: 0;
            --padding-end: 0;
            --border-color: transparent;
            --inner-padding-end: 0;
            --detail-icon-opacity: 1;
            & .profile-list-icon {
                width: 21px;
                margin: auto 20px auto auto;
            }
            & ion-label {
                padding: 8px 20px 7px 0;
                border-bottom: 1px solid #e6e6e6;
                margin: 0;
            }
            & ion-badge {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 18px;
                margin: 0 32px 0 0;
                padding: 3px 5px;
                border: 1px solid #abd6c7;
                color: #010100;
                font-size: 11px;
                border-radius: 9px;
            }
        }
    }
}

.profile-card-table {
    & .col-2-wrap {
        display: flex;
        & p {
            margin: 0;
            line-height: 14px;
            padding: 6px 5px;
            &:first-child {
                font-weight: 500;
                // flex-basis: 165px;
                width: 63%;
            }
        }
    }
}

.map-infobox {
    background: var(--ion-color-white);
    border: 2px solid #5bb091;
    border-radius: 6px;
    max-width: 200px;
    box-shadow: 0 3px 6px 0 #00000029;
    margin: 100px;
    position: relative;
    &::after {
        content: "";
        width: 17px;
        height: 17px;
        background: url("../../../assets/icons/chat-arrow.png") no-repeat;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &-banner {
        min-height: 100px;
        background-size: cover;
        position: relative;
        &-text {
            position: absolute;
            left: 10px;
            bottom: 10px;
        }
        & .remove-button {
            width: 26px;
            height: 26px;
            position: absolute;
            right: 6px;
            top: 8px;
            background-size: 13px;
            margin: 0;
            opacity: 1;
        }
        & p,
        & h5 {
            margin: 0;
            font-weight: 500;
        }
        & p {
            color: #fff;
        }
        & h5 {
            color: #cdde52;
        }
    }
    &-content {
        padding: 8px 10px 24px;
    }
    .profile-card-table {
        & .col-2-wrap {
            & p {
                font-size: 12px;
                &:first-child {
                    width: 37%;
                }
            }
        }
    }
}

.filter-modal {
    --background: white;
    --min-height: 100vh;
    --width: 100vw;
    --min-width: 100vw;
    --height: auto !important;
    padding: 40px;
    .heading {
        display: flex;
        align-items: center;
        margin-top: 60px;
        .head {
            flex-grow: 1;
            font-size: 24px;
            line-height: 24px;
        }
        .reset {
            width: 100px;
            text-align: right;
        }
        .close {
            font-size: 24px;
            transform: translate(-5px, 5px);
        }
    }
    ion-content {
        padding: 40px;
        // --padding-bottom: 40px;
    }
    .checkbox-item {
        text-align: right;
        ion-label {
            margin-right: 5px;
        }
    }
    .contentContainer {
        .input-select {
            margin: 0;
        }
    }
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.img {
    height: 24px;
}

app-header-icon {
    float: right;
}

.flex-end-align {
    align-items: flex-end;
}

.create-job-icon {
    width: 41px;
    height: 41px;
}

.fav-icon{
    width: 26px;
    height: 26px;
}

.review {
    &-box {
        display: flex;
        padding: 20px 0 15px 0;
        & .profile-pic {
            border: 2px solid #fff;
            box-shadow: 0 0 0 2px #5bb091;
            width: 60px;
            height: 60px;
            &-wrap {
                margin: 0;
            }
        }
    }
    &-content {
        margin-left: 12px;
        flex-grow: 1;
        & p {
            margin: 5px 0;
        }
        & a {
            margin-left: 10px;
        }
        .quote {
            line-height: 16px;
            margin: 15px 0;
        }
        & .rating-box {
            background: url("../../../assets/icons/rating-star-outline.png");
            background-size: contain;
            background-position-x: left;
            background-repeat: repeat-x;
            height: 25px;
            width: 138px;
            margin-bottom: 5px;
            &-inner {
                background: url("../../../assets/icons/rating-star.png");
                background-size: contain;
                background-position-x: left;
                background-repeat: repeat-x;
                height: 100%;
                width: 100px;
            }
        }
    }
}

.md .tabs-bottom {
    transform: translateY(-55px) !important;
}

.searchbar-clear-button {
  right: 40px !important;
}

.gm-ui-hover-effect>span {
  background-color: transparent;
}
